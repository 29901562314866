import Link from "next/link";

export default function FourOhFour() {
  return (
    <div className="px-4 py-20 bg-white rounded-md">
      <div className="flex flex-col items-center">
        <h1 className="font-bold text-gray-500 text-9xl">404</h1>

        <h6 className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
          Page not found
        </h6>

        <p className="mb-8 text-center text-gray-500 md:text-lg">
          The page you’re looking for doesn’t exist.
        </p>

        <Link
          href="/"
          className="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100"
          passHref
        >
          <button
            type="button"
            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            Go back home
          </button>
        </Link>
      </div>
    </div>
  );
}
